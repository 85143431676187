/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2021] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

.col-selected {
    background-color: rgba(26, 188, 156, .25) !important; /* Same as bg-soft-success. */
    color: #313a46 !important;
}

.table .thead-dark th {
    color: white;
}

.events-page-controls {
    justify-content: center;
    align-items: baseline;
    font-size: 1rem;
    font-weight: normal;

    .btn {
        font-size: 1.25rem;
        color: rgba(0,0,0,0.3) !important;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background: none;

        &:hover {
            color: rgba(0,0,0,0.5) !important;
            background: none;
        }

        &:active:focus, &:focus, &:active {
            box-shadow: none !important;
            background: none !important;
        }
    }
}

.nav-pills {
    background-color: $gray-100;
    border-radius: 0.25rem;
}