/*!
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2021] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

.footer_row {
  justify-content: space-between;
  align-items: baseline;

  .footer_logo {
    height: min-content;
  }

  .footer_version {
    height: min-content;
    text-align: center;
    font-size: 10px !important;
  }

  .footer_text {
    height: min-content;
    text-align: right;
    font-size: 0.75rem !important;
  }

  a.support {
    color: #f1f3fa;
    font-size: 10px;
    margin-left: 20px;
  }
}

@include media-breakpoint-down(sm) {
  .footer_version {
    display: none;
  }
}

@media (max-width: 400px) {
  .footer_logo img {
    display: none;
  }
}