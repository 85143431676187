/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2021] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

$md-modal-sidebar-min-width: 250px;

@include  media-breakpoint-down(md) {
    .md-modal.modal-lg,
    .md-modal.modal-xl {
        margin: 0 !important;
        max-width: 100% !important;
        height: 100vh;
    }
}

.md-modal {
    height: 90vh;

    &.modal-lg {
        max-width: 1000px;
    }

    &.modal-xl {
        max-width: 1600px;
    }

    .modal-content {
        height: 100%;

        .modal-header {
            padding: 0;
            border: none;

            .modal-title {
                display: flex;
                width: 100%;
                background-color: $black-sand;
                color: white;
                z-index: 50;

                .modal-nav {
                    height: 100%;
                    align-self: end;
                    flex-wrap: nowrap;

                    li {
                        a {
                            display: flex;
                            color: white;
                            border-bottom: 2px solid $action-blue;
                            cursor: pointer;
                            margin-top: auto;
                            vertical-align: text-bottom;

                            &.active {
                                color: $action-blue;
                                border-color: $action-blue;
                                background: none;
                            }

                            &.disabled {
                                color: $steel-grey;
                                border: transparent;
                            }
                        }
                    }
                }

                .modal-controls {
                    flex-grow: 1;
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row-reverse;
                    align-items: center;
                    padding: 0.5rem;
                    border-bottom: 2px solid $action-blue;
                    margin: 0 0 -1px;
                    overflow: hidden;

                    &.disabled {
                        border-color: rgba($gray-600, 0.2);
                    }

                    > button {
                        color: white;
                        height: 100%;
                        margin-left: 0.5rem;
                        white-space: nowrap;

                        @include media-breakpoint-down(sm) {
                            span {display: none}
                        }

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }
        }

        .modal-body {
            overflow: hidden;
            padding: 0;

            .tab-content, .tab-pane, .tab-pane > .row, > .row {
                height: 100%;
            }

            .modal-sidebar {
                flex-grow: 1;
                color: white;
                background-color: $black-sand;
                z-index: 100;
                min-width: $md-modal-sidebar-min-width;

                .modal-sidebar_wrapper {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    padding: 3rem 2rem;

                    .modal-sidebar_clear-btn {
                        padding: 0;
                        background-color: transparent !important;
                        box-shadow: none !important;
                        border: none !important;
                        text-decoration: underline;
                    }

                    .list-group {
                        border-radius: 0.75rem;

                        .list-group-item {
                            user-select: none;
                            text-align: left;
                            width: fit-content;
                            padding: 0 0.5rem;
                            margin-bottom: 0.5rem;
                            background-color: transparent;
                            color: white;
                            border: none;
                            border-radius: 0.75rem;
                        }

                        .list-group-item:hover {
                            color: $action-blue;
                        }

                        .list-group-item:active {
                            background-color: transparent;
                        }

                        .list-group-item + .list-group-item.active {
                            margin-top: 0;
                        }

                        .list-group-item.active {
                            background-color: $action-blue;
                            font-weight: bold;
                        }

                        .list-group-item.active:hover {
                            background-color: $steel-grey;
                            color: white;
                        }

                        .list-group-item.active:active {
                            background-color: $steel-grey;
                            color: white;
                        }
                    }
                }
            }

            .modal-section {
                flex-grow: 3;
                overflow-y: auto;

                .modal_grid {
                    width: 100%;
                    padding: 0 1rem;
                    margin: 0 0 25% 0;

                    .modal_card-wrapper {
                        padding: 0;
                        width: 100%;
                        height: 100%;
                        background: none;
                        border: none;
                        text-align: start;

                        .modal_card {
                            user-select: none;
                            height: 90%;
                            border: 1px solid $gray-200;
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                            box-shadow: 0 3px 3px 1px rgba($gray-600, 0.2);
                            transition: transform 0.15s ease-in-out;

                            .modal_card_body {
                                padding: 1rem;

                                .badge-outline-info {
                                    color: $action-blue;
                                    border-color: $action-blue;
                                }
                            }

                            &:hover {
                                transform: scale3d(1.05, 1.05, 1);
                            }

                            &.active {
                                box-shadow: 0 0 5px 1px $action-blue;
                                scroll-margin: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include  media-breakpoint-down(sm) {
    .modal-sidebar {
        display: none;
    }
}
