/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

$widget-overlay-bg: #0003;

.react-grid-layout {
    margin: 0 -24px;
}

.react-grid-item, .widget-overlay {
    border-radius: 0.25rem;
}

.react-grid-item > .react-resizable-handle::after {
    right: 5px;
    bottom: 5px;
    width: 10px;
    height: 10px;
}

.react-grid-item.react-grid-placeholder {
    background: $action-blue;
}

.editing .apexcharts-toolbar {
    display: none;
}

.widget-overlay {
    border: dashed 2px $widget-overlay-bg;
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 0.75rem;

    * {
        opacity: 0;
    }
}

.widget-overlay:hover,
.editing-widget > .widget-overlay {
    background-color: $widget-overlay-bg;
    border: none;

    * {
        opacity: 1;
    }
}

.widget-overlay:hover {
    cursor: move;
}

.widget-overlay:hover + .widget-body,
.editing-widget > .widget-body{
    filter: blur(5px);
}

.widget-edit-filler {
    flex-grow: 1;
    transition: flex 0.25s ease-in-out;
}

.widget-edit-btn {
    padding: 0;
    border: none;
    background: none;
    font-size: 1.5rem;
    text-shadow: 0 1px rgba(0, 0, 0, 0.4);
}

.widget-edit-textarea-label {
    position: absolute;
    margin-right: 3rem;
    left: 3rem;
    top: 3.5rem;
    font-weight: normal;
    font-size: 0.75rem;
    color: rgba(0,0,0,0.65);
    opacity: 0 !important;
    transition: opacity 0.25s linear;
    transition-delay: 0.25s;
}

.editing-widget .widget-edit-textarea-label {
    opacity: 1 !important;
    transition: visibility 0s, opacity 0.25s linear;
    transition-delay: 0.25s;

    &.invisible {
        transition: visibility 0s, opacity 0.25s linear;
        transition-delay: 0s;
    }
}

.widget-edit-textarea {
    flex: unset;
    margin: 0 5px;
    padding: 0 0 0 1rem;
    border: 0;
    width: 0 !important;
    height: 0;
    resize: none;
    border-radius: 0.25rem;
    transition: flex 0.25s ease-in-out, height 0.25s ease-in-out, padding 0.25s ease-in-out;

    &:focus {
        outline: none !important;
    }
}

.editing-widget .widget-edit-textarea {
    flex-grow: 1;
    padding: 0.5rem;
    height: 2.5rem;

    &.widget-edit-textarea-multiline {
        height: 100%;
    }
}

.react-resizable-handle {
    z-index: 100;
}

.widget-body {
    overflow: hidden;
}

.widget-text, .widget-error {

    .widget-body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.widget-text {

    .widget-text-multiline {
        overflow-x: hidden;
        white-space: pre-wrap;

        p {
            padding-right: 15px;
        }
    }
}

.widget-error {

    .widget-body {
        text-align: center;

        .uil-exclamation-circle {
            font-size: 3rem;
        }
    }
}

.widget-no-bg {
    background: none !important;
    box-shadow: none !important;
    border: none !important;

    .widget-overlay {
        background: none;
    }

    .widget-overlay:hover,
    .editing-widget > .widget-overlay {
        background-color: $widget-overlay-bg;
    }

    &.editing-widget {
        .widget-overlay {
            background-color: $widget-overlay-bg;
        }
    }
}

.modal-xl .modal-sidebar {
    max-width: 20%;
}

// Dashboard fullscreen mode
.dashboard-display.fullscreen {
    margin-bottom: auto;
}