/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

.DateRangePickerInput {
    border-radius: 0.25rem;
    border-color: lightgray;
    overflow: hidden;
}

.DateRangePickerInput:hover {
    border-color: gray;
}

.DateRangePickerInput_calendarIcon {
    margin: 0;
    padding-right: 0.25rem;
}

.DateInput {
    width: 80px;
}

.DateInput_input__disabled {
    font-style: normal;
}

.DateInput_input, .DateInput_input_1 {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 3px 0;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    height: 100%;
}

.DateRangePicker_picker {
    z-index: 99;
}

.DateInput_input__focused {
    border-bottom: 2px solid $action-blue;
}

.DayPicker {
    border-radius: 0.25rem;
}

.CalendarDay__selected_span {
    background: #85d3f4;
    border: 1px double $action-blue;
}

.CalendarDay__selected_span:hover {
    background: $action-blue;
    border: 1px double $action-blue;
}

.CalendarDay__selected {
    background: #27b1ec;
    border: 1px double #27b1ec;
}

.CalendarDay__selected:focus {
    background: #27b1ec;
    border: 1px double #27b1ec;
}

.CalendarDay__selected:hover {
    background: #27b1ec;
    border: 1px double #27b1ec;
}

// Dashboard time travel: Modify the closed state of the date picker to fit inside a button.
#timetravel_buttongroup {
    .DateInput_input {
        background-color: transparent;
        color: white;
    }
    .DateInput_input__focused {
        border-bottom: none;
    }

    .DateInput, .SingleDatePickerInput {
        background-color: transparent;
    }
}
