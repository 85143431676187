// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

@font-face {
  font-family: 'AvantGarde';
  font-weight: 1 699;
  src: url('../../../fonts/AvantGardeBook.ttf');
  src: local('AvantGardeBook'), local('AvantGardeBook'), url('../../../fonts/AvantGardeBook.ttf') format('truetype');
}

@font-face {
  font-family: 'AvantGarde';
  font-weight: 700 1000;
  src: url('../../../fonts/AvantGardeDemi.ttf');
  src: local('AvantGardeDemi'), local('AvantGardeDemi'), url('../../../fonts/AvantGardeDemi.ttf') format('truetype');
}

.text-disabled {
  color: #6c757d;
  opacity: 0.5;
}
