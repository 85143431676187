/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

.grecaptcha-badge { visibility: hidden; }

.scroll-table {
    .table thead {
        position: sticky;
        top: -1px;
    }
}

.hover-enlarge {
    user-select: none;
    transition: transform 0.15s ease-in-out;

    &:hover {
        transform: scale3d(1.05, 1.05, 1);
    }
}

.flex-fit {
    flex-grow: 0;
    flex-basis: fit-content;
}

.flex-xl-grow {
    flex-grow: 0;

    @include media-breakpoint-up(xl) {
        flex-grow: 1;
    }
}

.maxh-100 { max-height: 100% }
.maxh-50 { max-height: 50% }
.maxh-0 { max-height: 0 }

.maxw-100 { max-width: 100% }
.maxw-50 { max-width: 50% }
.maxw-0 { max-width: 0 }

.minh-100 { min-height: 100% }
.minh-50 { min-height: 50% }
.minh-0 { min-height: 0 }

.minw-100 { min-width: 100% }
.minw-50 { min-width: 50% }
.minw-0 { min-width: 0 }

.xl-maxh-100 {
    @include media-breakpoint-up(xl) {
        max-height: 100%;
    }
}

.xl-mb-0 {
    @include media-breakpoint-up(xl) {
        margin-bottom: 0 !important;
    }
}

.flex-collapse {
    transition: flex-grow 0.25s ease-out;
    overflow: hidden;
    flex-grow: 0;

    &.show {
        flex-grow: 1;
    }
}

.overflow-visible {
    overflow: visible !important;
}

.btn-primary, .btn-outline-primary:hover {
    color: white;

    &.btn-primary:disabled {
        color: white;
    }
}

.badge-primary {
    color: white;
}

.btn-link-hover {
    color: rgba(0,0,0,0.3) !important;

    &:hover {
      color: rgba(0,0,0,0.5) !important;

        &.text-danger {
            color: $danger !important;
        }

        &.text-success {
            color: $success !important;
        }
    }
}

.modal-header {
    background-color: $black-sand;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: white;

    .close {
        color: white;
    }
}

.btn-hide-disabled {
    &.btn.disabled, &.btn:disabled {
        opacity: 1;

        * { @extend .invisible }
    }

    &.btn-secondary.disabled, &.btn-secondary:disabled {
        color: white;
        background-color: $secondary;
        border-color: $secondary;
    }
}

.trim-hr {
    hr { display: none }
    :not(hr) + hr { display: block }
}
