/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */


.opacity-50 {
  opacity: 0.5;
}

.category-card {
  min-height: 4rem;
  box-sizing: border-box;

  &.selected {
    outline: 1px solid #6b727d;
  }

  &:hover {
    .options-button {
      visibility: visible;
    }
  }

  .options-button {
    visibility: hidden;
  }
}