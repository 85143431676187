/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

.search-tabs {

  .nav-tabs.nav-bordered {
    border-bottom: none;
    border-radius: 0.25rem 0.25rem 0 0;

    :first-child {
      border-radius: 0.25rem 0 0 0;
    }

    :last-child {
      border-radius: 0 0.25rem 0 0;
    }


    li {
      cursor: pointer;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: #eee;
      background-color: #eee;

      a {
        padding: 0.625rem 1.25rem;
      }
      a.active {
        z-index: 1;
        position: relative;
        border-bottom: 2px solid $primary;
      }
    }
  }
}
