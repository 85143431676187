/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

.station-type-card {
    .btn:focus {
        box-shadow: none !important;
    }
}

.station-group-card {
    height: 300px
}

.station-group-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.station-group-drop-zone {
    margin-top: 12px;
    margin-bottom: 12px;

    > div {
        height: 100%;
    }

    .station-group-drop-zone-title {
        @extend .text-muted;
        position: absolute;
        background-color: white;
    }

    &.droppable-active {
        @extend .border-primary;
        border-width: 2px !important;
        border-style: dashed !important;
        margin-left: -1px;
        margin-right: -1px;
        margin-top: 11px;
        margin-bottom: 11px;

        .station-group-drop-zone-title {
            display: none;
        }
    }
}

.station-group-schedule:hover {
    background-color: #cccccc1A;
}

.station-schedule-day-picker-block {
    @extend .border;
    border-left: none !important;

    &:first-child {
        @extend .border-left;
    }
}

.station-schedule-day-block {
    display: flex;
    padding: 0;
    border-right: $steel-grey 1px solid;

    &:last-child {
        border: none;
    }
}