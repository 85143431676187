/*!
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

$apexcharts-tooltip-max-width: 200px;
$apexcharts-tooltip-min-height: 0px;

$graph-report-card-img-sm-min-width: 100px;

.apexcharts-text {
  font-family: inherit !important;
}

.apexcharts-yaxis text, .apexcharts-xaxis text, .apexcharts-datalabels-group text {
  fill: #272f34 !important;
}

.apexcharts-tooltip {
  transform: translateY(100%);

  .toast {
    max-width: $apexcharts-tooltip-max-width;
    flex-basis: $apexcharts-tooltip-min-height;
  }

  .chart-tooltip-dot {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }

  .toast-body-data {
    padding-inline-start: 1rem
  }
}

.graph-report-card_wrapper {
  padding: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  text-align: start;

  .graph-report-card {
    height: 100%;
    user-select: none;
    margin-bottom: 0;
    border: 1px solid $gray-200;
    box-shadow: 0 3px 3px 1px rgba($gray-600, 0.2);
    transition: transform 0.15s ease-in-out;

    &.selected {
      box-shadow: 0 0 5px 1px $action-blue;
      scroll-margin: 20px;
    }
  }

  .graph-report-card_body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem;

    .badge-outline-info {
      color: $action-blue;
      border-color: $action-blue;
    }
  }

  .graph-report-card_details {
    flex-direction: column;
    flex-wrap: nowrap;
    min-width: 0;
    margin: 0;
    padding: 0;
    flex: 1;
  }

  .graph-report-card_image-lg {
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .graph-report-card_image-sm {
    flex-shrink: 0;
    flex-basis: auto;
    min-width: 100px;
    max-width: 25%;
    margin-left: 1rem;
    padding: 0;

    img { width: 100% }
  }
}
