/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2021] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

$graph-view__multi-card-breakpoint: lg;
$graph-view-content__unselected-border-color: #0003;

.graph-view.unselected {
  .graph-view-sidebar {
    display: block;
  }

  .graph-view-content {
    display: block;

    @include media-breakpoint-down($graph-view__multi-card-breakpoint) {
      display: none;
    }

    > .card {
      border: 2px dashed $graph-view-content__unselected-border-color;
      background: none;
    }
  }
}

.graph-view {
  height: 70vh;

  .graph-view-sidebar {
    height: 100%;

    @include media-breakpoint-down($graph-view__multi-card-breakpoint) {
      display: none;
    }
  }

  .graph-view-sidebar_list {
    display: flex;
    flex-direction: column;
    max-height: 65vh;
    height: 100%;
  }

  .graph-view-sidebar_list-header {
    display: flex;
    background-color: white;
    margin: 0;
    padding: 0.5rem;
    width: 100%;
    flex-grow: 0;
  }

  .graph-view-sidebar_list-group {
    flex: 1;
    overflow: auto;
    margin-bottom: 36px;
    border-radius: 0;
  }

  .graph-view-sidebar_list-btn {
    text-align: left;
    width: 100%;

    button {
      width: 100%;
      color: white;
    }
  }

  .graph-view-sidebar_list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    box-sizing: border-box;
    border: $secondary 1px solid;

    &.selected {
      outline: 1px solid #6b727d;
    }
  }

  .graph-view-content {
    height: 100%;
    flex-grow: 2;
  }

  .graph-view-info {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .graph-view-info_top {
    overflow: hidden;
    padding-bottom: 0.75rem;
    width: 100%;
    flex-grow: 1;

    .time-travel {
      position: absolute;
      right: 20%;
      z-index: 1;
    }
  }

  .graph-view-info__close {
    @extend .close;

    display: none;
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 500;

    @include media-breakpoint-down($graph-view__multi-card-breakpoint) {
      display: block;
    }
  }

  @include media-breakpoint-down($graph-view__multi-card-breakpoint) {
    .apexcharts-toolbar {
      margin-right: 10px;
    }
  }

  .graph-view-info__expand {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 0.375rem;
    border: none;
    background: none;
    font-size: 1rem;
    color: rgba(0,0,0,0.3);
    box-shadow: none;

    &:hover {
      color: rgba(0,0,0,0.5);
    }
  }

  .graph-view-info_bottom {
    border-top: 1px solid #eef2f7;
    padding-top: 0.75rem;
    flex-basis: auto;
    height: 15%;

    > .row {
      height: 100%;
    }
  }

  .graph-view-info__options {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-basis: auto;
    width: fit-content;
    align-items: flex-start;

    .btn {
      font-size: 1rem;
      padding: 0 0.375rem;
      border: none;
      background: none;
      color: rgba(0, 0, 0, 0.5) !important;
      box-shadow: none;

      &:hover {
        color: #333 !important;

        &.text-danger {
          color: $danger !important;
        }
      }
    }
  }

  .graph-view-info__details {
    color: $steel-grey;
  }
}

.graph-creator-modal {

  .graph-creator-invalid {
    position: absolute;
    background-color: $danger;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 200;

    * {
      color:white; 
      font-weight: bold;
      margin: 0.5rem;
    }
  }

  .graph-creator-type {

    .graph-creator-type_options {
      position: absolute;
      background-color: white;
      bottom: 0;
      right: 24px;
      width: calc(100% - 24px);
      margin: 0;
      padding: 0 1rem 0 2rem;
      border-top: 1px solid #eef2f7;
      justify-content: space-between;
      align-items: baseline;

      > .col {
        align-items: baseline;
        width: fit-content;
        display: flex;
        height: fit-content;
        padding: 12px 12px 12px 0;
        flex-basis: auto;

        > label {
          white-space: nowrap;
          padding-right: 0.5rem;
        }

        > .custom-control-inline {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .graph-creator-options {
    padding: 1.5rem;

    > div > .card:last-child {
      margin-bottom: 25vh;
    }
  }

  .graph-creator-preview {
    flex-direction: column;
    justify-content: space-between;
    margin: auto !important;
    height: 100%;
    padding: 3rem 0;

    .apexcharts-title-text {
      height: 1rem;
    }

    .graph-creator-preview_graph {
      margin-bottom: 2rem;
      height: 75%;
      padding: 0;

      .apexcharts-title-text {
        display: none;
      }
    }

    .graph-creator-preview_controls {
      flex: 0;
      padding: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .graph-view {
    max-height: 70vh;
  }
}